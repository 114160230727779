// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content .section3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 40vh;
}
.main-content .section3 .title1div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.main-content .section3 .title1div .title1 {
  font-size: 48px;
  margin-bottom: 20px;
}
.main-content .section3 .title1div .titlepara {
  font-size: 20px;
}

.end-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.end-card1 {
  text-align: center;
  padding: 20px;
  max-width: 900px;
}
.end-card1 h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
.end-card1 h2 {
  font-size: 16px;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard.scss"],"names":[],"mappings":"AAII;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AAHR;AAKQ;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAHZ;AAKY;EACI,eAAA;EACA,mBAAA;AAHhB;AAMY;EACI,eAAA;AAJhB;;AAWA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AARJ;;AAWA;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;AARJ;AAUI;EACI,eAAA;EACA,mBAAA;AARR;AAWI;EACI,eAAA;EACA,WAAA;AATR","sourcesContent":[".main-content {\r\n    // background: url('../../assets/imges/background.webp');\r\n    // height: 100vh;\r\n\r\n    .section3 {\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        flex-direction: column;\r\n        text-align: center;\r\n        min-height: 40vh; // Controls space allocated for .section3\r\n\r\n        .title1div {\r\n            display: flex;\r\n            flex-direction: column;\r\n            align-items: center;\r\n            text-align: center;\r\n\r\n            .title1 {\r\n                font-size: 48px;\r\n                margin-bottom: 20px;\r\n            }\r\n\r\n            .titlepara {\r\n                font-size: 20px;\r\n            }\r\n        }\r\n\r\n    }\r\n}\r\n\r\n.end-card {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 20px;\r\n}\r\n\r\n.end-card1 {\r\n    text-align: center;\r\n    padding: 20px;\r\n    max-width: 900px;\r\n\r\n    h1 {\r\n        font-size: 24px;\r\n        margin-bottom: 10px;\r\n    }\r\n\r\n    h2 {\r\n        font-size: 16px;\r\n        color: #666;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
