/* Project Import will be here */

/* Const,Let & Var will be here */
let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj));
};

let newState = {
    user: {
		data:'',
    }
   
};
export const initialState ={ user: {
    data:'',
}}


export const reducer = (state, action) => {
    switch(action.type){
        case 'LOGIN_SUCCESS' :
            return{
                ...state,user:action.user
            };

            default:
                return state;
    }
}

export default function   (state, action) {
    switch(action.type){
        case 'LOGIN_SUCCESS' :
            return{
                ...state,user:action.user
            };

            default:
                return state;
    }
}

// export default function (state = newState, action) {
//     newState = cloneObject(state);
//     switch (action.type) {
// 		case actions.LOGIN_SUCCESS:
// 		newState.user.data = action.data;   
// 		return newState;
        
//         default:
//             return newState;
//     }

// export default reducer;

/* Const,Let & Var will be here */


