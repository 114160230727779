import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Form, Select, Card, message, Spin, Typography } from 'antd';
import './startYourMock.scss';
import apiService from 'src/utils/apiService';
import Notification from 'src/components/notification/notification';
import Progressbar from 'src/components/proressBar';

const { Option } = Select;
const { Title } = Typography;

const StartYourMock = () => {
    const location = useLocation();
    const { avatarName } = location.state || {};
    const navigate = useNavigate();
    const jwt = localStorage.getItem('jwt');
    const userId = localStorage.getItem('userId');
    const [isBtnLoading, setisBtnLoading] = useState(true);
    const [form] = Form.useForm();
    const [complexity, setComplexity] = useState([]);
    const [role, setRole] = useState([]);
    useEffect(() => {
        getAvatar();
        getComplexity();
        getInterviewRole();
    }, []);

    const getAvatar = async () => {
        console.log("avatarName", avatarName);

        if (avatarName === undefined) {
            navigate("/interviewer/interviewee");
        }
    }
    const getComplexity = async () => {
        apiService(`startyourmock/getComplexityList`, 'get', '', false, jwt,
            mock => {
                console.log(mock)
                if (mock.data.success) {
                    setComplexity(mock.data.complexity);
                    setisBtnLoading(false);
                } else {
                    setisBtnLoading(false);
                    Notification.openNotificationSuccess(mock.data.message)
                }
            },
            (error) => {

            });
    }
    const getInterviewRole = async () => {
        apiService(`startyourmock/getInterviewRoleList`, 'get', '', false, jwt,
            mock => {
                console.log(mock)
                if (mock.data.success) {
                    setRole(mock.data.role);
                    setisBtnLoading(false);
                } else {
                    setisBtnLoading(false);
                    Notification.openNotificationSuccess(mock.data.message)
                }
            },
            (error) => {

            });
    }

    const handleSubmit = async (values) => {
        setisBtnLoading(true);

        const payload = {
            userId: userId,
            avatarId: avatarName.id,
            roleId: values.role,
            complexityId: values.complexity,
            duration: Number(values.duration),
        };

        try {
            // Make API call to save data in the database
            apiService(`startyourmock/mockDetails`, 'post', payload, false, jwt,
                mock => {
                    console.log("mock start", mock)
                    if (mock.data.success) {
                        setisBtnLoading(false);
                        Notification.openNotificationSuccess(mock.data.message)
                        // Navigate to the interview page after saving
                        // navigate("/interview/interviewee");
                        navigate("/interview/interviewee", {
                            state: {
                                avatarName: avatarName,
                                role: role.find((roles) => roles.id === values.role),
                                complexity: complexity.find((complex) => complex.id === values.complexity),
                                duration: values.duration,
                            }
                        });

                    } else {
                        setisBtnLoading(false);
                        Notification.openNotificationSuccess(mock.data.message)
                    }
                },
                (error) => {

                });

        } catch (error) {
            console.error("Error saving data:", error);
            message.error("Failed to save data. Please try again.");
        }
    };

    return (

        <div className="selection-screen">
            {isBtnLoading &&
                (<div className="spinner-overlay">
                    {/* <Progressbar /> */}
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                </div>
                )}
            <Card className="selection-card">
                <div className="selection-card-header">
                    <Title level={2}>Choose Your Interview Preferences !!</Title>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        role: '',
                        complexity: '',
                        duration: '5',
                    }}
                >

                    <Form.Item
                        label="Role"
                        name="role"
                        rules={[{ required: true, message: 'Please select a role!' }]}
                    >
                        <Select size="large" placeholder="Select Role" style={{ width: '100%' }}>
                            {role.map((level) => (
                                <Option key={level.id} value={level.id}>
                                    {level.role}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Complexity"
                        name="complexity"
                        rules={[{ required: true, message: 'Please select complexity!' }]}
                    >
                        <Select size="large" placeholder="Select Complexity" style={{ width: '100%' }}>
                            {complexity.map((level) => (
                                <Option key={level.id} value={level.id}>
                                    {level.complexLevel}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Duration (in minutes)"
                        name="duration"
                        rules={[{ required: true, message: 'Please select duration!' }]}
                    >
                        <div contentEditable='false' className="dropdown duration">5 minutes</div>
                        {/* <Select size="large" placeholder="Select Duration" style={{ width: '100%' }}>
                            <Option value="15">15</Option>
                            <Option value="30">30</Option>
                            <Option value="45">45</Option>
                            <Option value="60">60</Option>
                        </Select> */}
                    </Form.Item>

                    <Form.Item>
                        <div className="buttonint">
                            <Button className="btn-int" type="primary" htmlType="submit">
                                <b>Next</b>
                            </Button>
                        </div>
                    </Form.Item>
                </Form>

            </Card>
        </div>
    );
};

export default StartYourMock;
