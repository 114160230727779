import React, { useEffect, useState, useRef } from 'react';
import { Button, Typography, Spin, Progress } from 'antd';
import './interviewPage.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from 'src/utils/apiService';
import Progressbar from '../../components/proressBar';


const InterviewPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {
        avatarName,
        role,
        complexity,
        duration
    } = location.state || {}; // Access state from navigation

    const jwt = localStorage.getItem('jwt');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('name');
    const [isRecording, setIsRecording] = useState(false);
    const [sessionCompleted, setSessionCompleted] = useState(false);
    const [audioComplete, setAudioComplete] = useState(false);
    const [isVoiceRecognized, setIsVoiceRecognized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [transcripts, setTranscripts] = useState('');
    const [sessionResponse, setSessionResponse] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [audioUrls, setAudioUrls] = useState([]);
    const [sessionId, setSessionId] = useState('');
    const [sessionDataId, setSessionDataId] = useState('');
    const [professionalDet, setProfessionalDet] = useState(null);
    const [remainingTime, setRemainingTime] = useState(duration * 60);
    const audioRef = useRef(null);
    const recognitionRef = useRef(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        getPRofessionalDetails();
    }, []);

    useEffect(() => {
        console.log("Transcript:", transcripts);
        if (transcripts.length > 0 && !isVoiceRecognized) {
            // const lastAddedTranscript = transcripts;
            console.log("Last added transcript from updated state:", transcripts);
            sendMessage(transcripts);
            setTranscripts('');
        }
    }, [transcripts, isVoiceRecognized])

    const playAudio = (audioUrl, questionText) => {
        setAudioComplete(true);
        if (audioRef.current) {
            audioRef.current.pause();
        }
        audioRef.current = new Audio(audioUrl);
        audioRef.current.play();
        audioRef.current.onended = () => {
            setQuestions((prevResponse) => [...prevResponse, questionText]);
            setAudioComplete(false);

        };
    };

    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset audio to the beginning
        }
    }
    useEffect(() => {
        let timer;
        if (isRecording && remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (remainingTime === 0) {
            clearInterval(timer);
            // stopAudio();
            setSessionCompleted(true);
            setIsVoiceRecognized(false);
            // navigate('/feedback/interviewee', {
            //     state: {
            //         session: sessionDataId
            //     }
            // });// Replace with your desired route for feedback
        }

        return () => clearInterval(timer); // Clear timer on unmount or when timer stops
    }, [isRecording, remainingTime, navigate]);

    const getPRofessionalDetails = () => {
        apiService(`onboarding/professionalDetails/${userId}`, 'get', '', false, jwt,
            result => {
                console.log("result", result);

                if (result.data) {
                    setProfessionalDet(result.data);
                    setIsLoading(false);
                }
            },
            (error) => {
            });
    };

    const startSession = (payload) => {
        setIsLoading(true);
        try {
            // Make API call to save data in the database
            apiService(`session/startSession`, 'post', payload, false, jwt,
                response => {
                    console.log("mock", response);
                    setAudioUrls((prevUrls) => [...prevUrls, response.data.session.speech_url]);
                    playAudio(response.data.session.speech_url, response.data.session.greeting_text);
                    setSessionId(response.data.session.session_id);
                    setIsRecording(true);
                    setIsLoading(false);

                },
                (error) => {
                    console.error("Error starting session:", error);
                });

        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const addSession = () => {

        try {
            let payload = {
                "userId": userId,
                "duration": Number(duration)
            }
            // Make API call to save data in the database
            apiService(`session/addSession`, 'post', payload, false, jwt,
                response => {
                    console.log("mock", response);
                    setSessionDataId(response.data.sessionId);
                },
                (error) => {
                    console.error("Error starting session:", error);
                });

        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const sendMessage = (message) => {
        setIsLoading(true);
        let payLoad = {
            "session_id": sessionId,
            "message": message
        }
        try {
            // Make API call to save data in the database
            apiService(`session/sendMessageSession`, 'post', payLoad, false, jwt,
                response => {
                    console.log("mock", response);
                    if (response.data.session.speech_url !== '') {
                        setAudioUrls((prevUrls) => [...prevUrls, response.data.session.speech_url]);
                        playAudio(response.data.session.speech_url, response.data.session.response_text);
                    }
                    const questionsAnswers = {
                        'sessionId': sessionDataId,
                        'question': questions[questions.length - 1],
                        'answer': message,
                        'response_audio_url': response.data.session.speech_url || null
                    };
                    console.log("questionsAnswers", questionsAnswers);
                    postQuestions(questionsAnswers);
                    setSessionResponse((prevResponse) => [...prevResponse, questionsAnswers]);
                    setIsLoading(false);
                },
                (error) => {
                    console.error("Error starting session:", error);
                });

        } catch (error) {
            console.error("Error saving data:", error);
        }
    };


    const handleBeginStopClick = async () => {
        if (!isRecording) {

            const payload = {
                actor_choice: avatarName.name,
                candidate_name: userName,
                experience: `${professionalDet.experience}`,
                current_role: professionalDet.role,
                mock_role: role.role,
                complexity: complexity.complexLevel,
                duration: duration
            };
            startSession(payload);
            addSession();
        } else {
            setIsRecording(false);
            stopAudio();
            navigate('/feedback/interviewee', {
                state: {
                    session: sessionDataId
                }
            });
            // stopVoiceRecognition();
        }
    };

    const handleVoiceRecognition = async () => {
        if (!audioComplete) {
            if (!isVoiceRecognized) {
                setIsVoiceRecognized(true);
                // Begin voice recognition
                startVoiceRecognition();
            } else {
                setIsVoiceRecognized(false);
                stopVoiceRecognition();
            }
        }
    }

    const playTextAsSpeech = (text) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(text);
            window.speechSynthesis.speak(utterance);
        } else {
            console.error("Text-to-Speech not supported in this browser.");
        }
    };

    const startVoiceRecognition = () => {
        if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
            alert('Your browser does not support speech recognition. Please try a different browser.');
            return;
        }
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = false;
        recognitionRef.current.lang = 'en-US';
        let fullTranscript = "";
        recognitionRef.current.onresult = (event) => {
            const lastResult = event.results[event.results.length - 1];
            const lastTranscript = lastResult[0].transcript;
            console.log("transcript", lastTranscript);

            // for (let i = event.resultIndex; i < event.results.length; i++) {
            //     console.log("fulltranscript", i + " " + fullTranscript);
            //     fullTranscript += event.results[i][0].transcript + " ";
            //     console.log("transcript", i + " " + fullTranscript);
            // }

            // Only update `transcripts` if the result is final
            if (lastResult.isFinal) {
                // console.log("called final", fullTranscript);
                // Append the latest transcript to the existing text
                setTranscripts((prevTranscript) => prevTranscript + ' ' + lastTranscript);
                // setTranscripts((prevTranscripts) => [...prevTranscripts, fullTranscript]);
            }
        };

        recognitionRef.current.onerror = (error) => {
            console.error("Speech recognition error:", error);
        };
        recognitionRef.current.start();
        setIsVoiceRecognized(true);
    };

    const stopVoiceRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsVoiceRecognized(false);
        }
    };

    const postQuestions = (questionsAnswers) => {
        try {
            console.log("sessionResponse", sessionResponse);

            // Make API call to save data in the database
            apiService(`session/addQuestions`, 'post', questionsAnswers, false, jwt,
                response => {

                },
                (error) => {
                    console.error("Error starting session:", error);
                });

        } catch (error) {
            console.error("Error saving data:", error);
        }
    }
    // Calculate minutes and seconds from remainingTime
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return (<>
        {isLoading &&
            (<div className="spinner-overlay">
                <div className="spinner">
                    <Spin size="large" />
                    {/* <Progressbar /> */}
                    {/* <Progress
                        steps={10}
                        percent={50}
                        
                        percentPosition={{ align: 'center', type: 'inner' }}
                        size={[200, 20]}
                        strokeColor="#E6F4FF"
                    /> */}
                    {/* <Spin size="large" /> */}
                </div>
            </div>
            )}
        {!isRecording ? < div className="voice-mock-screen">
            <div className="section3">
                <div className="title1div">
                    <h3 className="title1">Welcome {userName}</h3>
                </div>

                <div className="buttonint">
                    <Button className="btn-int" type="primary" onClick={handleBeginStopClick}>
                        <b>Start Your Mock Interview</b>
                    </Button>
                </div>
            </div>
        </div> :
            < div className="voice-mock-screen">
                <div className='mock-header'>
                    <Typography className='custom-title' >Welcome, {userName}</Typography>

                    <div className="timer">
                        Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </div>
                    {!sessionCompleted &&
                        <Button type="primary" className={`${audioComplete ? 'disabled' : ''}`} onClick={handleVoiceRecognition} ref={buttonRef} >
                            <b>{isVoiceRecognized ? 'Done With Answer' : 'Record your Answer'}</b>
                        </Button>
                    }
                    <Button type='primary' className="btn-int" onClick={handleBeginStopClick}>
                        <b>   {!sessionCompleted ? 'Stop' : 'Done'}</b>
                    </Button>

                </div>


                {questions.length > 0 && <div className="response-section">
                    {questions.map((response, index) => (
                        <p><strong>Q{index + 1}:</strong> {response}</p>
                    ))}

                </div>}
            </div >
        }
    </>
    );
};

export default InterviewPage;
