// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page .ant-form-item:last-child {
  margin-bottom: 5px;
}
.profile-page .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {
  display: none;
}
.profile-page .paddingcln {
  padding: 0px 8px;
}
.profile-page .setting-form-actions {
  text-align: center;
}
.profile-page .form-align {
  width: 150%;
  margin-left: -5pc;
  border-radius: 0px;
}
.profile-page .ant-form-item .ant-form-item-control-input input, .profile-page .fzTwMX .ant-form-item .ant-form-item-control-input textarea {
  color: #5A5F7D;
  border-radius: 0px;
  height: 40px;
}
.profile-page .ant-form-item .ant-select-single .ant-select-selector {
  padding: 0 20px;
  height: 40px !important;
  border-radius: 0px !important;
  margin: 5px;
  border: 1px solid #E3E6EF;
}
.profile-page .fzTwMX .ant-form-item .ant-form-item-label label {
  height: fit-content;
  margin: 7px;
  margin-top: 9px !important;
}
.profile-page .dstcNg {
  padding: 12px;
  border-radius: 0px;
  border: 1px solid #E3E6EF;
  margin: -3px;
}
.profile-page .clear-btn {
  border: 1px solid !important;
  border-radius: 0px !important;
}
.profile-page .form-align1 {
  width: 130%;
  margin-left: -5pc;
  border-radius: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/profile.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;AADR;AAGI;EACI,aAAA;AADR;AAIA;EACI,gBAAA;AAFJ;AAIA;EACA,kBAAA;AAFA;AAIA;EAEI,WAAA;EACA,iBAAA;EACA,kBAAA;AAHJ;AAKA;EACI,cAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAOC;EACG,eAAA;EACA,uBAAA;EACA,6BAAA;EACA,WAAA;EACA,yBAAA;AALJ;AAOA;EAEI,mBAAA;EACA,WAAA;EACA,0BAAA;AANJ;AAQA;EACI,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;AANJ;AAQA;EACI,4BAAA;EACA,6BAAA;AANJ;AAUA;EACI,WAAA;EACA,iBAAA;EACA,8BAAA;AARJ","sourcesContent":["\r\n.profile-page{\r\n    .ant-form-item:last-child {\r\n        margin-bottom: 5px;\r\n    }\r\n    .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture {\r\n        display: none;\r\n    }\r\n    \r\n.paddingcln{\r\n    padding: 0px 8px;\r\n}\r\n.setting-form-actions{\r\ntext-align: center;\r\n}\r\n.form-align{\r\n    \r\n    width: 150%;\r\n    margin-left: -5pc;\r\n    border-radius: 0px;\r\n}\r\n.ant-form-item .ant-form-item-control-input input, .fzTwMX .ant-form-item .ant-form-item-control-input textarea {\r\n    color: #5A5F7D;\r\n    border-radius: 0px;\r\n    height: 40px;\r\n\r\n    \r\n}\r\n .ant-form-item .ant-select-single .ant-select-selector {\r\n    padding: 0 20px;\r\n    height: 40px !important;\r\n    border-radius: 0px !important;\r\n    margin: 5px;\r\n    border: 1px solid #E3E6EF;\r\n}\r\n.fzTwMX .ant-form-item .ant-form-item-label label {\r\n    \r\n    height: fit-content;\r\n    margin: 7px;\r\n    margin-top: 9px !important;\r\n}\r\n.dstcNg {\r\n    padding: 12px;\r\n    border-radius: 0px;\r\n    border: 1px solid #E3E6EF;\r\n    margin: -3px;\r\n}\r\n.clear-btn{\r\n    border: 1px solid !important;\r\n    border-radius: 0px !important;\r\n}\r\n\r\n\r\n.form-align1{\r\n    width: 130%;\r\n    margin-left: -5pc;\r\n    border-radius: 20px !important;\r\n}\r\n}\r\n// padding: 12px;\r\n// border-radius: 0px;\r\n// border: 1px solid #E3E6EF;\r\n// margin: -3px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
