import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Welcome from '../pages/welcome/welcome-page';
import Signup from '../pages/signup/signup-page';
import Login from '../pages/login/login-page';
import YourSelf from '../pages/yourself/yourself-page';
import YourSkill from '../pages/yourskill/yourskill-page';
import ProgrammingLanguages from '../pages/programmingLanguages/programmingLanguages-page';
import InterestArea from '../pages/interestArea/interestArea-page';
import Dashboard from '../pages/dashboard/dashboard';
import StartYourMock from '../pages/mockDetails/startYourMock';
import InterviewPage from '../pages/interview/interviewPage';
import Header from '../pages/header/header';
import FeedbackScreen from '../pages/feedback/feedback';
import InterviewersList from '../pages/interviewer/interviewer';
import Profile from '../pages/profile/profile-page';
import SetPaasword from '../pages/setPassword/setPassword-page';

const HomeRoutes = () => {
	var login = localStorage.getItem('login');
	var userRole = localStorage.getItem('userRole');
	return (
		login === "true" ?
			<div className='main-wrapper'>
				<Header />
				<div className='content'>
					<Routes>
						<Route path="/yourself/:userroleid" element={<YourSelf />} />
						<Route path="/yourskill/:userroleid" element={<YourSkill />} />
						<Route path="/programminglanguages/:userroleid" element={<ProgrammingLanguages />} />
						<Route path="/interestarea/:userroleid" element={<InterestArea />} />
						<Route path="/dashboard/:userroleid" element={<Dashboard />} />
						<Route path="/startyourmock/:userroleid" element={<StartYourMock />} />
						<Route path="/interview/:userroleid" element={<InterviewPage />} />
						<Route path="/interviewer/:userroleid" element={<InterviewersList />} />
						<Route path="/feedback/:userroleid" element={<FeedbackScreen />} />
						<Route path="/profile/:userroleid" element={<Profile />} />
						<Route path="/setpassword" element={<SetPaasword />} />
						<Route path="/" element={<Navigate to="/dashboard/interviewee" />} />
						<Route path="*" element={<Navigate to="/dashboard/interviewee" />} />
					</Routes>
				</div>
			</div>
			:
			<Routes>
				<Route path="/" element={<Navigate to="/welcome" />} />
				<Route path="/welcome" element={<Welcome />} />
				<Route path="*" element={<Navigate to="/welcome" />} />
				<Route path="/signup/:userroleid" element={<Signup />} />
				<Route path="/login/:userroleid" element={<Login />} />
				<Route path="/yourself/:userroleid" element={<YourSelf />} />
				<Route path="/yourskill/:userroleid" element={<YourSkill />} />
				<Route path="/programminglanguages/:userroleid" element={<ProgrammingLanguages />} />
				<Route path="/interestarea/:userroleid" element={<InterestArea />} />
				<Route path="/setpassword" element={<SetPaasword />} />
			</Routes>
	);

};

export { HomeRoutes };
