import React, { useState } from 'react';
import { Button, Typography, Input, message, Form, notification, Spin } from 'antd';
import apiService from 'src/utils/apiService'; // Adjust the path based on your file structure
import './feedback.scss';
import { useLocation } from 'react-router-dom';
import Notification from 'src/components/notification/notification';

const { Title } = Typography;
const { TextArea } = Input;

const FeedbackScreen = () => {
    const location = useLocation();
    const {
        session
    } = location.state || {};
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const jwt = localStorage.getItem('jwt'); // Assuming the JWT token is stored in local storage
    const userId = localStorage.getItem('userId'); // Assuming the user ID is also stored

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleSaveFeedback = async () => {
        if (!feedback.trim()) {
            message.warning('Please enter your feedback');
            return;
        }

        setIsSubmitting(true);

        let payload = {
            "sessionId": session,
            "feedBack": feedback
        }

        try {
            // API call to submit feedback
            apiService(`session/addFeedBack`, 'post', payload, false, jwt,
                response => {
                    if (response.data.SUCCESS) {
                        Notification.openNotificationSuccess(response.data.MESSAGE);
                        setFeedback(''); // Clear the feedback textarea after submission
                    } else {
                        Notification.openNotificationSuccess('Failed to submit feedback. Please try again.');
                    }
                },
                error => {
                    message.error('Failed to submit feedback. Please try again.');
                }
            );
        } catch (error) {
            console.error('Error submitting feedback:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="feedback-screen" >
            {isSubmitting &&
                
                
                
                (<div className="spinner-overlay">
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                </div>
                )}
            <Title level={2}>Thank you!</Title>
            <Form layout="vertical">
                <Form.Item>
                    <TextArea
                        rows={4}
                        value={feedback}
                        onChange={handleFeedbackChange}
                        placeholder="Please provide your feedback here"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={handleSaveFeedback} loading={isSubmitting}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default FeedbackScreen;
