import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Result, Spin } from 'antd';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';

import './interestArea-page.scss';
import 'antd/dist/reset.css';
import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import apiService from "../../utils/apiService";
import Notification from "../../components/notification/notification";



import linkareaimg from 'src/assets/imges/likeareanew.png';
const menus = [
    {
        icon: <ArrowRightOutlined />,
    }
];
const { Meta } = Card;

const InterestArea = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // let yourself = location.state.yourself;
    // let programminglanguages = location.state.programminglanguages;
    // let userLang = programminglanguages.userLang;
    // let userSkillsVa = location.state.userSkills;
    // let userSkills = userSkillsVa.userSkills;
    const yourselfData = localStorage.getItem('yourself');
    const programminglanguages = localStorage.getItem('programminglanguages');
    const userSkills = localStorage.getItem('userSkills');
    // console.log(yourselfData);
    // console.log(programminglanguages);
    // console.log(userSkills);
    const jwt = localStorage.getItem('jwt');

    const [interestAreaList, setInterestAreaList] = useState([]);
    const [selectItemId, setSelectItemId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [interestAreaListAdd, setInterestAreaListAdd] = useState([]);
    const [selectIdList, setSelectIdList] = useState([]);

    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");




    useEffect(() => {
        getInterestAreaList();
    }, []);


    const getInterestAreaList = () => {
        apiService(`signup/favlist`, 'get', '', false, jwt,
            result => {
                if (result.data) {
                    const resultPush = [];
                    result.data.map(data => {
                        resultPush.push({
                            id: data.id,
                            name: data.type,
                            check: false,

                        });
                    });
                    setInterestAreaList(resultPush);
                    setInterestAreaListAdd(resultPush);
                    setIsLoading(true);
                }
            },
            (error) => {
            });
    };

    const handleSubmit = () => {
        const yourself = JSON.parse(yourselfData);
        const userLang = JSON.parse(programminglanguages);
        const userSkill = JSON.parse(userSkills);
        console.log(selectIdList);
        console.log(userLang);
        console.log(userSkill);
        console.log(yourself);
        if (selectIdList.length > 0) {
            setisBtnLoading(true);
            if (yourself.isStudent == "Y") {
                apiService(`onboarding/userdetails`, 'post', {
                    "isStudent": yourself.isStudent,
                    "degree": yourself.degree,
                    "specialization": yourself.specialization,
                    "graduationYear": yourself.graduationYear,
                    "collegeInfoDto": {
                        "cgpa": yourself.collegeInfoDto.cgpa,
                        "collegeName": yourself.collegeInfoDto.collegeName
                    },
                    "userLang": userLang,
                    "userSkills": userSkill,
                    userDomain: selectIdList,

                }, false, jwt,
                    role => {
                        if (role.data.message == "Welcome to our platform") {
                            setisBtnLoading(false);
                            Notification.openNotificationSuccess(role.data.message)
                            const userRole = localStorage.getItem('userRole');
                            const flow = localStorage.getItem('flow');
                            console.log('flow');
                            console.log(flow);
                            if (flow === 'login') {
                                localStorage.setItem('login', true);
                                navigate('/dashboard/interviewee');
                                setisBtnLoading(false);
                            } else {
                                navigate('/login/interviewee');
                                localStorage.clear();
                            }

                        } else {
                            setisBtnLoading(false);
                            Notification.openNotificationSuccess(role.data.message)
                        }
                    },
                    (error) => {

                    });
            } else {
                apiService(`onboarding/userdetails`, 'post', {
                    "isStudent": yourself.isStudent,
                    "degree": yourself.degree,
                    "specialization": yourself.specialization,
                    "graduationYear": yourself.graduationYear,
                    "professionalInfoDto": {
                        "experience": yourself.professionalInfoDto.experience, "companyName": yourself.professionalInfoDto.companyName,
                        "role": yourself.professionalInfoDto.role
                    },
                    "userLang": userLang,
                    "userSkills": userSkill,
                    userDomain: selectIdList,

                }, false, jwt,
                    result => {
                        if (result.data.message == "Welcome to our platform") {
                            setisBtnLoading(false);
                            Notification.openNotificationSuccess(result.data.message)
                            const userRole = localStorage.getItem('userRole');
                            const flow = localStorage.getItem('flow');
                            console.log('flow');
                            console.log(flow);
                            if (flow === 'login') {
                                localStorage.setItem('login', true);
                                navigate('/dashboard/interviewee');
                                setisBtnLoading(false);
                            } else {
                                navigate('/login/interviewee');
                                localStorage.clear();
                            }
                        } else {
                            setisBtnLoading(false);
                            Notification.openNotificationSuccess(result.data.message)
                        }
                    },
                    (error) => {

                    });
            }

        } else {
            Notification.openNotificationFaliure("Prefer to choose your most skilled option out this listed!")

        }
    };

    const handleClickCard = (id, check) => {
        if (!check) {
            selectIdList.push({
                id: id,
            });
        } else {
            if (selectIdList.length > 0) {
                var index = selectIdList.indexOf(id)
                selectIdList.splice(index, 1);
            }
        }

        const resultPush = [];
        if (selectIdList.length > 0) {
            interestAreaList.map(data => {
                if ((id == data.id || data.check == true)) {
                    if (id == data.id && check == true) {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: false,

                        });
                    } else {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: true,

                        });
                    }
                } else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,

                    });
                }

            });
        } else {
            interestAreaListAdd.map(data => {
                if (id == data.id || data.check == true) {
                    if (check == false) {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: true,

                        });
                    } else {
                        resultPush.push({
                            id: data.id,
                            name: data.name,
                            check: false,

                        });
                    }

                } else {
                    resultPush.push({
                        id: data.id,
                        name: data.name,
                        check: false,

                    });
                }
            });

        }



        setInterestAreaList(resultPush);
        console.log(selectIdList)

    }




    const handleClick = () => {
        navigate('/login/interviewee');
        // // localStorage.setItem('login', true);
        // // this.props.navigation.navigate("/Dashboard", { login: true });
        // this.setState({ signupSuccess: true });
    }
    return (
        <div className="interestArea_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin size="large" />
                </div> :
                <div class='interestArea-container'>
                    <div class='left-container'>
                        <header>
                            <div class="header-name">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        {userRoleId === "2" ? <h2>Area you like to be interviewed ?</h2> : <h2>Area you like to interview ?</h2>}
                                    </Col>
                                </Row>
                            </div>
                            <div class="header-name">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <h4>Prefer to choose your most skilled option out this listed</h4>
                                    </Col>
                                </Row>
                            </div>
                            <div class='card'>
                                <Row>
                                    {interestAreaList.map(item => {
                                        return (
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Card className={item.check == true ? 'ant-card-select' : ''} style={{ width: 252, marginTop: 16, height: 70 }} onClick={() => handleClickCard(item.id, item.check)}>
                                                    <div>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} push={15}>
                                                                {item.check == true ?
                                                                    <i > <CheckCircleTwoTone twoToneColor="#52c41a" /></i>
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Meta
                                                        description={item.name}
                                                    />
                                                </Card>
                                            </Col>
                                        )
                                    })
                                    }
                                </Row>

                                <footer>
                                    <div class='footer_details'>
                                        <Link to={userRoleId === '2' ? "/yourskill/interviewee" : "/yourskill/interviewer"}> <Button type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button></Link>
                                        {!isBtnLoading && <Button onClick={handleSubmit} type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large"> FINISH </Button>}
                                        {isBtnLoading && <Button onClick={handleSubmit} type="primary" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoading} size="large"> FINISH </Button>}
                                    </div>
                                </footer>
                            </div>
                        </header>
                    </div>

                    <div class='right-container'>
                        <h1>May be a good idea to have skill on more than one languages - Object oriented vs Functional</h1>
                        <img src={linkareaimg} />
                    </div>

                </div>
            }
        </div>
    );

}

export default InterestArea;