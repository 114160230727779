import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Typography, Row, Col, Spin, Card } from 'antd';
import './interviewer.scss';
import apiService from 'src/utils/apiService';
import Notification from 'src/components/notification/notification';
import Babu from "../../assets/imges/BabuOff.png";
import dhoni from "../../assets/imges/dhoni.jpg";
import vijay from "../../assets/imges/vijay.jpg";
import ajith from "../../assets/imges/ajith.jpg";
import nayanthara from "../../assets/imges/nayanthara.jpg";
import mahesh from "../../assets/imges/mahesh.jpg";

const { Title, Text } = Typography;

const Interviewer = () => {
    const navigate = useNavigate();
    const jwt = localStorage.getItem('jwt');
    const [selectedAvatar, setSelectedAvatar] = useState(1);
    const [isBtnLoading, setisBtnLoading] = useState(true);
    const [avatars, setAvatars] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of cards per page
    const images = {
        "BabuOff.png": Babu,
        "dhoni.jpg": dhoni,
        "vijay.jpg": vijay,
        "ajith.jpg": ajith,
        "nayanthara.jpg": nayanthara,
        "mahesh.jpg": mahesh,
    };
    useEffect(() => {
        getAvatar();
    }, []);

    const getAvatar = async () => {
        apiService(`startyourmock/getAvatarList`, 'get', '', false, jwt,
            mock => {
                if (mock.data.success) {
                    setAvatars(mock.data.avatar);
                    setisBtnLoading(false);
                } else {
                    setisBtnLoading(false);
                    Notification.openNotificationSuccess(mock.data.message);
                }
            },
            (error) => {
                setisBtnLoading(false);
            });
    };

    const handleAvatarClick = (id) => {
        setSelectedAvatar(id);
        let selectedInterviewer = avatars.find((avatar) => avatar.id === id);
        navigate("/startyourmock/interviewee", {
            state: {
                avatarName: selectedInterviewer
            }
        });
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate paginated data
    const paginatedAvatars = avatars.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Total pages for pagination dots
    const totalPages = Math.ceil(avatars.length / itemsPerPage);

    return (
        <>
            {isBtnLoading &&
                (<div className="spinner-overlay">
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                </div>
                )}
            <div className="interviewers-container">
                <div className="header">
                    <Text type="secondary" className="subheader">YOUR BEST DESTINATION</Text>
                    <Title level={2}>Choose your mock interviewer !!</Title>
                </div>

                <Row gutter={16} className="cards-container">
                    {paginatedAvatars.map((interviewer, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={4}>
                            <Card
                                className={`interviewer-card ${selectedAvatar === interviewer.id ? 'selected' : ''}`}
                                hoverable
                                onClick={() => handleAvatarClick(interviewer.id)}
                            >
                                <div className="card-header">
                                    <div className="avatar-info">
                                        <Title level={5} className='margin-title ellipsis'>{interviewer.name}</Title>
                                        <Text type="secondary" className='ellipsis'>{interviewer.role}</Text>
                                    </div>
                                    <Avatar
                                        key={interviewer.id}
                                        src={images[interviewer.path]}
                                        size={64}
                                        shape="circle"
                                        className={`avatar`}
                                    />
                                </div>
                                <div className="card-body">
                                    <Text className="rank">#{interviewer.rank}</Text>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {totalPages > 1 && <div className="footer">
                    <Text className="more-text">{totalPages}+ MORE</Text>
                    <Button className="read-more" type="primary">READ MORE</Button>
                </div>}

                {/* Dot-Based Pagination */}
                <div className="pagination-dots">
                    {[...Array(totalPages)].map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index + 1 === currentPage ? 'active' : ''}`}
                            onClick={() => onPageChange(index + 1)}
                        />
                    ))}
                </div>
            </div>

        </>
    );
};

export default Interviewer;
