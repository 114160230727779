import React, { useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Avatar, Button, Modal } from "antd";
import {
  useLocation,
  NavLink,
  useResolvedPath,
  useNavigate,
} from "react-router-dom";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { HomeRoutes } from "src/routes";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Div,
  SmallScreenAuthInfo,
  SmallScreenSearch,
  TopMenuSearch,
} from "./style";
import MenueItems from "./MenueItems";
import { Scrollbar } from "react-scrollbars-custom";
import { ThemeProvider } from "styled-components";
import interviewsImg from "../../../../src/assets/imges/job-interview.png";
import scheduleImg from "../../../../src/assets/imges/waiting-list.png";
import logout from "../../../../src/assets/imges/exit.png";
import userImg from "../../../../src/assets/imges/user-img.png";
import dashboardImg from "../../../../src/assets/imges/dashboard.png";
import userGropuImg from "../../../../src/assets/imges/user-group.png";
//   import interviewerImg from '../../../../src/assets/imges/user-group.png';
import transaction from "../../../../src/assets/imges/transaction.png";
import configuration from "../../../../src/assets/imges/configuration.png";
import exam from "../../../../src/assets/imges/exam.png";
import couponImg from "../../../../src/assets/imges/coupon.png";
import interviewerImg from "../../../../src/assets/imges/interview.png";

const { darkTheme } = require("../../../config/theme/themeVariables");

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;


const MainLayoutPresentational = () => {
  var login = localStorage.getItem("login");
  var userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const { confirm } = Modal;

  let location = useLocation();
  const onCollapse = (data) => {
    console.log(data);
    setCollapsed(data);
  };

  const [collapsed, setCollapsed] = useState(false);
  const [topMenu, settopMenu] = useState(false);
  const [ChangeLayoutMode, setChangeLayoutMode] = useState(false);
  const [rtl, setrtl] = useState(false);
  const [changeRtl, setchangeRtl] = useState(false);
  const [changeLayout, setchangeLayout] = useState(false);
  const [changeMenuMode, setchangeMenuMode] = useState(false);
  const [toggleCollapsed, settoggleCollapsed] = useState(false);

  // const { ChangeLayoutMode, rtl, changeRtl, changeLayout, changeMenuMode } = this.props;

  const left = !rtl ? "left" : "right";
  const darkMode = ChangeLayoutMode;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    toggleCollapsedMobile();
  }, []);
  const updateDimensions = () => {
    console.log("window.innerWidth --> ", window.innerWidth);
    if (window.innerWidth >= 990 && window.innerWidth <= 1200) {
      setCollapsed(true);
    }
  };
  const toggleCollapsedOpen = () => {
    setCollapsed(false);
    //   settopMenu(false );
  };
  const toggleCollapsedClose = () => {
    setCollapsed(true);
    //   settopMenu(true);
  };
  const toggleCollapsedCloseMenu = () => {
    if (window.innerWidth <= 990) {
      setCollapsed(true);
    }
  };

  const toggleCollapsedMobile = () => {
    console.log("MOBILE COLLAPSE WIDTH --> ", window.innerWidth);
    if (window.innerWidth <= 990) {
      console.log("DidMyMobileJob  --> ", window.innerWidth);
      console.log("DidMyMobileJob  --> ", collapsed);
      setCollapsed(true);
    }
  };

  const footerStyle = {
    padding: "20px 30px 18px",
    color: "rgba(0, 0, 0, 0.65)",
    fontSize: "14px",
    background: "rgba(255, 255, 255, .90)",
    width: "100%",
    boxShadow: "0 -5px 10px rgba(146,153,184, 0.05)",
  };

  const SideBarStyle = {
    // margin: '63px 0 0 0',
    padding: "15px 15px 55px 15px",
    overflowY: "auto",
    height: "100vh",
    position: "fixed",
    [left]: 0,
    zIndex: 998,
  };

  const renderView = ({ style }) => {
    const customStyle = {
      marginRight: "auto",
      [rtl ? "marginLeft" : "marginRight"]: "-17px",
    };
    return <div style={{ ...style, ...customStyle }} />;
  };

  const renderThumbVertical = ({ style }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#ffffff16",
      [left]: "2px",
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: "absolute",
      width: "6px",
      transition: "opacity 200ms ease 0s",
      opacity: 0,
      [rtl ? "left" : "right"]: "2px",
      bottom: "2px",
      top: "2px",
      borderRadius: "3px",
    };
    return <div style={thumbStyle} />;
  };

  const renderThumbHorizontal = ({ style }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "#ffffff16",
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const onRtlChange = () => {
    const html = document.querySelector("html");
    html.setAttribute("dir", "rtl");
    changeRtl(true);
  };

  const onLtrChange = () => {
    const html = document.querySelector("html");
    html.setAttribute("dir", "ltr");
    changeRtl(false);
  };

  const modeChangeDark = () => {
    changeLayout(true);
  };

  const modeChangeLight = () => {
    changeLayout(false);
  };

  const modeChangeTopNav = () => {
    changeMenuMode(true);
  };

  const modeChangeSideNav = () => {
    changeMenuMode(false);
  };

  const onEventChange = {
    onRtlChange,
    onLtrChange,
    modeChangeDark,
    modeChangeLight,
    modeChangeTopNav,
    modeChangeSideNav,
  };
  const resolvedPath = useResolvedPath();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(resolvedPath.pathname);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const logoutConfirm = () => {
    confirm({
      title: "Do you want to logout?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        navigate({ pathname: "/logout" });
      },
      onCancel() { },
    });
  };

  return (
    <Div darkMode={darkMode}>
      <Layout className="layout">
        {(
          <Layout>
            <Content style={{ margin: "24px 16px 0", height: "100%" }}>
              <div
                className="site-layout-background"
                style={{
                  borderRadius: 5,
                  overflow: "hidden",
                  minHeight: "100%",
                }}
              >
                <HomeRoutes />
              </div>
            </Content>
          </Layout>
        )}
      </Layout>
    </Div>
  );
};

export default MainLayoutPresentational;

// <Layout style={{ height: '100%' }}>
// 	{login == "true" ?
// 		<Sider
// 			// breakpoint="lg"
// 			collapsedWidth="0"
// 			collapsible collapsed={collapsed} onCollapse={onCollapse}>
// 			<Link to="/" className='dashboad-header'>
// 				<h2>SKILLINGO</h2>
// 			</Link>
// 			{userRole == "INTERVIEWEE" ?
// 				<Menu className="custom_menu" theme="dark" mode="inline" selectedKeys={[location.pathname]}>
// 					{intervieweeMenus.map((menu, i) => (
// 						<Menu.Item key={menu.route} icon={menu.icon} >
// 							<Link to={menu.route}>{menu.name}</Link>
// 						</Menu.Item>
// 					))}
// 					{/* <footer>

// 						<header >
// 							<div class="avatar">
// 								<Row className='interviewProfile-head'>
// 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
// 										<Avatar src={userImg} />
// 									</Col>
// 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
// 										<h3>Rajesh</h3>
// 									</Col>
// 								</Row>
// 							</div>
// 						</header>
// 					</footer> */}

// 				</Menu> : userRole == "INTERVIEWER" ? <Menu className="custom_menu" theme="dark" mode="inline" selectedKeys={[location.pathname]}>
// 					{interviewerMenus.map((menu, i) => (
// 						<Menu.Item key={menu.route} icon={menu.icon} >
// 							<Link to={menu.route}>{menu.name}</Link>
// 						</Menu.Item>
// 					))}
// 					{/* <footer className='interviewer-footer'>
// 						<header >
// 							<div class="avatar">
// 								<Row className='interviewProfile-head'>
// 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
// 										<Avatar src={userImg} />
// 									</Col>
// 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
// 										<h3>Rajesh</h3>
// 									</Col>
// 								</Row>
// 							</div>
// 						</header>
// 					</footer> */}
// 				</Menu> : <Menu className="custom_menu" theme="dark" mode="inline"
// 					selectedKeys={[location.pathname]}>
// 					{adminMenus.map((menu, i) => (
// 						<Menu.Item key={menu.route} icon={menu.icon} >
// 							<Link to={menu.route}>{menu.name}</Link>
// 						</Menu.Item>
// 					))}
// 					{/* <footer className='admin-footer'>
// 						<header >
// 							<div class="avatar">
// 								<Row className='interviewProfile-head'>
// 									<Col xs={24} sm={24} md={4} lg={4} xl={4}>
// 										<Avatar src={userImg} />
// 									</Col>
// 									<Col xs={24} sm={24} md={12} lg={12} xl={12} className='user-details'>
// 										<h3>Rajesh</h3>
// 									</Col>
// 								</Row>
// 							</div>
// 						</header>
// 					</footer> */}
// 				</Menu>}
// 		</Sider> : null}
// 	{/* <ImageComponent style={{ width: '100%', marginTop: 15 }} /> */}
// 	<Layout>
// 		{/* <Header className="site-layout-sub-header-background" style={{ padding: 0 }} /> */}
// 		<Content style={{ margin: '24px 16px 0', height: '100%' }}>
// 			<div className="site-layout-background" style={{ borderRadius: 5, overflow: 'hidden', minHeight: '100%' }}>
// 				<HomeRoutes />
// 			</div>
// 		</Content>
// 		{/* <Footer style={{ textAlign: 'center' }}	>human managed </Footer> */}
// 	</Layout>
// </Layout>