import React from 'react';
import './App.css';
import './static/css/style.css';
import reportWebVitals from './reportWebVitals';

import MainLayout from './pages/main-layout/main-layout-page';

function App() {
  return <MainLayout />;
}

export default App;
