import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from "antd";
const { Title } = Typography;
const Progress = styled.div`
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 10px;
  border-radius: 10px;
  background-color: #eee;
`;

const Progressbar = () => {
    const [filled, setFilled] = useState(0);
    const [animate, setAnimate] = useState(true); // To control transition

    useEffect(() => {
        if (filled < 100) {
            const timer = setTimeout(() => setFilled(filled + 2), 50);
            setAnimate(true); // Ensure transition is applied
            return () => clearTimeout(timer); // Clear the timer to avoid memory leaks
        } else {
            // Pause for a moment at 100% before resetting
            const resetTimer = setTimeout(() => {
                setAnimate(false); // Remove transition when resetting
                setFilled(0);
            }, 500);
            return () => clearTimeout(resetTimer);
        }
    }, [filled]);

    return (
        <div>
            <Progress>
                <div className="selection-card-header">
                    <Title level={2}>Loading...</Title>
                </div>
                <div
                    style={{
                        height: '100%',
                        width: `${filled}%`,
                        backgroundColor: '#15C872',
                        transition: animate ? 'width 0.5s' : 'none' // Apply transition only if animate is true
                    }}
                ></div>
                {/* <span className="progressPercent" style={{ position: 'absolute', right: '10px', top: '-20px' }}>
                    {filled}%
                </span> */}
            </Progress>
        </div>
    );
};

export default Progressbar;
