import styled from 'styled-components';
import { Button } from 'antd';

const PageHeaderStyle = styled.div`
  margin-bottom: 25px;
  
  .page-header-actions {
    display: flex;
    gap: 10px;

    button.ant-btn-white {
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
        color: #5f63f2;
      }

      i + span,
      svg + span,
      img + span {
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
      }
    }
  }
`;

export { PageHeaderStyle };
