import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './header.scss';

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login/interviewee');
    };

    // User menu items
    const userMenuItems = [
        {
            key: 'logout',
            label: (
                <Button type="text" onClick={handleLogout}>
                    Logout
                </Button>
            ),
        },
    ];

    return (
        <div className="dashboard">
            <div className="header">
                <Dropdown
                    menu={{
                        items: userMenuItems,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <Avatar icon={<UserOutlined />} className="avatar" />
                </Dropdown>
            </div>
        </div>
    );
};

export default Header;
