import React from 'react';
import { Menu } from 'antd';
import { NavLink, useResolvedPath } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import interviewsImg from '../../../../src/assets/imges/job-interview.png';
import scheduleImg from '../../../../src/assets/imges/waiting-list.png';
import logout from '../../../../src/assets/imges/exit.png';
import userImg from '../../../../src/assets/imges/user-img.png';
import dashboardImg from '../../../../src/assets/imges/dashboard.png';
import userGropuImg from '../../../../src/assets/imges/user-group.png';
import interviewerImg from '../../../../src/assets/imges/user-group.png';


const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {

  var login = localStorage.getItem('login');
	var userRole = localStorage.getItem('userRole');
  const resolvedPath = useResolvedPath(); 
  const path = resolvedPath.pathname;
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >

{userRole == "INTERVIEWEE" ?
<>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
               <img className='nav-img' src={interviewsImg} alt="" />
            </NavLink>
          )
        }
        key="interviews"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
       My Interviews
        </NavLink>
      </Menu.Item>  
       <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/schedule`}>
                 <img src={scheduleImg} alt="" />
            </NavLink>
          )
        }
        key="schedule"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/schedule`}>
        Schedule
        </NavLink>
      </Menu.Item>  
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/profile`}>
               <img src={userImg} alt="" />
            </NavLink>
          )
        }
        key="profile"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
        Profile
        </NavLink>
      </Menu.Item> 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/logout`}>
               <img src={logout} alt="" />
            </NavLink>
          )
        }
        key="logout"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/logout`}>
        Logout
        </NavLink>
      </Menu.Item> 
    </>
    : userRole == "INTERVIEWER" ?
    <>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
               <img className='nav-img' src={interviewsImg} alt="" />
            </NavLink>
          )
        }
        key="interviews"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
       My Interviews
        </NavLink>
      </Menu.Item>  
       <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/slots`}>
                 <img src={scheduleImg} alt="" />
            </NavLink>
          )
        }
        key="slots"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/slots`}>
        My Slots
        </NavLink>
      </Menu.Item>  
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/profile`}>
               <img src={userImg} alt="" />
            </NavLink>
          )
        }
        key="profile"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
        Profile
        </NavLink>
      </Menu.Item> 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/logout`}>
               <img src={logout} alt="" />
            </NavLink>
          )
        }
        key="logout"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/logout`}>
        Logout
        </NavLink>
      </Menu.Item> 
    </>
    :
    <>
    <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
               <img className='nav-img' src={dashboardImg} alt="" />
            </NavLink>
          )
        }
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
       Dashboard
        </NavLink>
      </Menu.Item> 
 
       <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/interviewee`}>
                 <img src={userGropuImg} alt="" />
            </NavLink>
          )
        }
        key="interviewee"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/interviewee`}>
        Interviewee
        </NavLink>
      </Menu.Item>  
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/interviewer`}>
               <img className='nav-img' src={userImg} alt="" />
            </NavLink>
          )
        }
        key="interviewer"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/interviewer`}>
        Interviewer
        </NavLink>
      </Menu.Item> 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
               <img className='nav-img' src={interviewsImg} alt="" />
            </NavLink>
          )
        }
        key="interviews"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
        Interviews
        </NavLink>
      </Menu.Item> 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/profile`}>
               <img src={userImg} alt="" />
            </NavLink>
          )
        }
        key="profile"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/profile`}>
        Profile
        </NavLink>
      </Menu.Item> 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/logout`}>
               <img src={logout} alt="" />
            </NavLink>
          )
        }
        key="logout"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/logout`}>
        Logout
        </NavLink>
      </Menu.Item> 
    </>
}

    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
