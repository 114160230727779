export class ApiUrls {

    // local Env


    static apiEnvironment = "https://ai-mockinterview.testleaf.com/bff/"; // `node-api
    static apiJavaEnvironment = "https://ai-mockinterview.testleaf.com/"; // java`-api


    // static apiEnvironment = "http://localhost/bff/"; // `node-api
    // static apiJavaEnvironment = "http://localhost/"; // java`-api

    // Dev Env

    //  static apiEnvironment = "https://staging-dev.skilingo.com/bff/"; // node-api
    //  static apiJavaEnvironment = "https://staging-dev.skilingo.com/"; // java-api


    // QA Env

    // static apiEnvironment = "https://sandbox.skilingo.com/bff/"; // node-api
    // static apiJavaEnvironment = "https://sandbox.skilingo.com/"; // java-api

    // Prod Env

    // static apiEnvironment = "https://www.skilingo.com/bff/"; // node-api
    // static apiJavaEnvironment = "https://www.skilingo.com/"; // java-api
};

// local Env
//const Environment = "http://localhost/";

const Environment = "https://ai-mockinterview.testleaf.com/";

// Dev Env
// const Environment = "https://staging-dev.skilingo.com/";

// QA Env
// const Environment = "https://sandbox.skilingo.com/";

// Prod Env
//const Environment = "https://www.skilingo.com/";

export default Environment;