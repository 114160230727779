// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imges/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: #f0f2f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.dashboard .avatar {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/header/header.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,wCAAA;EACA,aAAA;EACA,mDAAA;AADR;AAII;EACI,eAAA;AAFR","sourcesContent":[".dashboard {\r\n\r\n    .header {\r\n        position: sticky;\r\n        top: 0;\r\n        width: 100%;\r\n        display: flex;\r\n        justify-content: flex-end;\r\n        align-items: center;\r\n        padding: 10px;\r\n        background-color: #f0f2f5;\r\n        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\r\n        z-index: 1000;\r\n        background: url('../../assets/imges/background.webp');\r\n    }\r\n\r\n    .avatar {\r\n        cursor: pointer;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
