// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selection-screen {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* To provide some padding around the content */
  width: 100vw;
  height: 100vh;
  max-width: 500px;
  margin: auto;
  box-sizing: border-box;
  /* Prevent padding from affecting width */
}

.selection-card-header {
  word-spacing: 1px;
  letter-spacing: 1.5px;
}

.selection-card {
  padding: 20px;
  width: 100%;
  text-align: center;
  border: none;
  background-color: white;
}

.duration {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/mockDetails/startYourMock.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,+CAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,yCAAA;AACJ;;AAEA;EACI,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,aAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;AACJ;;AAIA;EACI,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ","sourcesContent":[".selection-screen {\r\n    background:transparent;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 20px;\r\n    /* To provide some padding around the content */\r\n    width: 100vw;\r\n    height: 100vh;\r\n    max-width: 500px;\r\n    margin: auto;\r\n    box-sizing: border-box;\r\n    /* Prevent padding from affecting width */\r\n}\r\n\r\n.selection-card-header {\r\n    word-spacing: 1px;\r\n    letter-spacing: 1.5px;\r\n}\r\n\r\n.selection-card {\r\n    padding: 20px;\r\n    width: 100%;\r\n    text-align: center;\r\n    border: none;\r\n    background-color: white;\r\n}\r\n\r\n \r\n\r\n.duration {\r\n    background-color: #fff;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 10px;\r\n    font-size: 16px;\r\n    height: 40px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
