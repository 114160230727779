import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Menu, Avatar, Card } from "antd";
import { UserOutlined } from '@ant-design/icons';
import './dashboard.scss';

const Dashboard = () => {
    const navigate = useNavigate();


    const handleClick = () => {
        navigate({ pathname: '/interviewer/interviewee' });
    };

    return (
        <div className="main-content">
            {/* Main Content */}
            <div className="section3">
                <div className="title1div">
                    <h3 className="title1">Welcome To Testleaf</h3>
                </div>

                <div className="buttonint">
                    <Button className="btn-int" type="primary" onClick={handleClick}>
                        <b>Start Your Mock Interview</b>
                    </Button>
                </div>
            </div>
            <div className='end-card'>
                <Card className='end-card1'>
                    <h1>Crack your next interview.</h1>
                    <h2>We designed mock interview process that can help  you to identify pain
                        points in the interview process. Together, we shaped the new standard.</h2>

                </Card>

            </div>
        </div>
    );
};

export default Dashboard;
