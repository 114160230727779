import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Spin } from 'antd';
import './login-page.scss';
import 'antd/dist/reset.css';
import { ArrowLeftOutlined, ArrowRightOutlined, RedoOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import loginImg from 'src/assets/imges/skilingo-login.png';

import apiService from "../../utils/apiService";
import Notification from "../../components/notification/notification";


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        checked: null,
        mobile: '',
    });
    let { edtechemail } = useParams();
    let { edtechotp } = useParams();
    let { userroleid } = useParams();
    console.log("userRoleId");
    console.log(userroleid);
    const [mobile, setMobile] = useState("");
    const [otp, setOtp] = useState(edtechotp === undefined ? "" : edtechotp);
    const [email, setEmail] = useState(edtechemail === undefined ? "" : edtechemail);
    const [userRoleId, setUserRoleId] = useState(userroleid === "interviewee" ? "2" : userroleid === "admin" ? "1" : "3");
    const [password, setPassword] = useState("");
    const [otpInput, setOtpInput] = useState(false);
    const [url, setUrl] = useState("");
    const [mobileForm, setMobileForm] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [emailForm, setEmailForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [loginOtpEmail, setLoginOtpEmail] = useState(false);
    const [loginPasswordEmail, setLoginPaaswordEmail] = useState(false);
    const [forgotpasswordform, setforgotpasswordform] = useState(false);
    const [isBtnLoadingOtp, setisBtnLoadingOtp] = useState(false);
    const [submitDataCheck, setsubmitDataCheck] = useState(false);
    const [passwordType, setpasswordType] = useState("password");
    const [emailValue, setemailValue] = useState("");

    useEffect(() => {
        localStorage.clear();
        setTimeout(() => {
            setIsLoading(true);
        }, 1000);
        console.log("EDTECH EMAIL --->", edtechemail);
        console.log("EDTECH OTP --->", edtechotp);
        if (otp !== null && otp !== "") {
            console.log("I'm INSIDE EDTECH LOGIC");
            // setEmail(edtechemail);
            // setOtp(edtechotp);
            handleSubmitEmailWithOtp();
        }
    }, []);

    const handleSubmitMobile = () => {
        apiService(`login/mobile`, 'post', { mobile: mobile, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data) {
                    Notification.openNotificationSuccess(result.data.message)
                    // setOtpForm(true);
                    // setMobileForm(false);
                }
            },
            (error) => {

            });
    };

    const handleSubmitOTP = () => {
        apiService(`login/verifyotp`, 'post', { mobile: mobile, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data) {
                    Notification.openNotificationSuccess(result.data.message)
                    // dispatch({
                    //     type: 'LOGIN_SUCCESS',
                    //     user: result.data,
                    // });
                    // localStorage.setItem('login', true);
                    // window.location = '/interviews'
                }
            },
            (error) => {

            });
    };

    const resendOtp = () => {
        apiService(`signup/resendotp?email=` + email + "&userRoleId=" + "2", 'get', '', false, '',
            result => {
                if (result.data.success == true) {
                    Notification.openNotificationSuccess(result.data.message);
                    setisBtnLoadingOtp(false);
                } else {
                    setisBtnLoadingOtp(false);
                    Notification.openNotificationSuccess(result.data.message);
                }
            },
            (error) => {

            });
    };

    const handleSubmitEmail = () => {
        setisBtnLoading(true);
        apiService(`login/email`, 'post', { email: email, password: password, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data.success == true) {
                    dispatch({
                        type: 'LOGIN_SUCCESS',
                        user: result.data,
                    });
                    console.log(result.data);
                    localStorage.setItem('profilecompleted', result.data.profilecompleted);
                    localStorage.setItem('loginData', JSON.stringify(result.data));
                    localStorage.setItem('userRole', result.data.role);
                    localStorage.setItem('jwt', result.data.jwt);
                    localStorage.setItem('userId', result.data.userid);
                    localStorage.setItem('flow', 'login');
                    localStorage.setItem('name', result.data.firstname + " " + result.data.lastname);

                    if (result.data.profilecompleted == "Y") {
                        setsubmitDataCheck(false);
                        Notification.openNotificationSuccess(result.data.message)
                        localStorage.setItem('login', true);

                        if (result.data.role === "INTERVIEWEE") {
                            navigate('/dashboard/interviewee');
                            // window.location = '/interviews'
                        } else if (result.data.role === "INTERVIEWER") {
                            navigate('/interviewer/interviews');
                            // window.location = '/interviwer/interviews'
                        } else {
                            navigate('/admin/dashboard');
                            // window.location = '/admin/dashboard'
                        }
                        setisBtnLoading(false);
                        // setOtpInput(true);
                    } else {
                        localStorage.setItem('profilecompleted', "N");
                        // localStorage.setItem('jwt', result.data.jwt);
                        Notification.openNotificationSuccess("Your profile is not completed please complete your profile!")
                        if (result.data.firstname != null) {
                            // navigate('/yourself');
                            userRoleId === '2' ? navigate("/yourself/interviewee") : navigate("/yourself/interviewer");
                        } else {
                            // navigate('/signup');
                            userRoleId === '2' ? navigate("/signup/interviewee") : navigate("/signup/interviewer");
                        }
                    }


                } else {
                    setisBtnLoading(false);
                    setsubmitDataCheck(false);
                    Notification.openNotificationFaliure(result.data.message)
                }
            },
            (error) => {

            });
    };

    const handleSubmitEmailWithOtp = () => {
        setsubmitDataCheck(true);
        setemailValue(email);
        localStorage.setItem('loginEmail', email);
        if (otp === "" && password === "") {
            setisBtnLoading(true);
            apiService(`login/emailotp`, 'post', { email: email, userRoleId: userRoleId }, false, '',
                result => {
                    if (result.data.success === true) {
                        setsubmitDataCheck(false);
                        Notification.openNotificationSuccess(result.data.message);
                        setOtp("")
                        setLoginOtpEmail(true);
                        setisBtnLoading(false);
                        // dispatch({
                        //     type: 'LOGIN_SUCCESS',
                        //     user: result.data,
                        // });
                        // localStorage.setItem('login', true);
                        // localStorage.setItem('loginData', JSON.stringify(result.data));
                        // localStorage.setItem('userRole', result.data.role);
                        // localStorage.setItem('jwt', result.data.jwt);
                        // localStorage.setItem('userId', result.data.userid);
                        // localStorage.setItem('name', result.data.firstname);

                        // if (result.data.role == "INTERVIEWEE") {
                        //     window.location = '/interviews'
                        // } else if (result.data.role == "INTERVIEWER") {
                        //     window.location = '/interviwer/interviews'
                        // } else {
                        //     window.location = '/admin/dashboard'
                        // }
                        // setisBtnLoading(false);
                        // // setOtpInput(true);
                    } else {
                        setsubmitDataCheck(false);
                        setisBtnLoading(false);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });
        } else if (password !== "") {
            handleSubmitEmail()
        }
        else {
            setisBtnLoading(true);
            var apiUrl = "login/verifyemailotp?email=" + email + "&otp=" + otp + "&userRoleId=" + userRoleId;
            apiService(apiUrl, 'get', '', false, '',
                result => {
                    if (result.data.success == true) {
                        setsubmitDataCheck(false);
                        setLoginOtpEmail(true);
                        setisBtnLoading(false);
                        dispatch({
                            type: 'LOGIN_SUCCESS',
                            user: result.data,
                        });
                        console.log(result.data);
                        localStorage.setItem('flow', 'login');
                        localStorage.setItem('loginData', JSON.stringify(result.data));
                        localStorage.setItem('userRole', result.data.role);
                        localStorage.setItem('jwt', result.data.jwt);
                        localStorage.setItem('userId', result.data.userid);
                        localStorage.setItem('name', result.data.firstname + " " + result.data.lastname);
                        localStorage.setItem('profilecompleted', result.data.profilecompleted);

                        if (result.data.profilecompleted == "Y") {
                            Notification.openNotificationSuccess(result.data.message);

                            localStorage.setItem('login', true);

                            if (result.data.role == "INTERVIEWEE") {
                                navigate('/dashboard/interviewee');
                                // window.location = '/interviews'
                            } else if (result.data.role == "INTERVIEWER") {
                                navigate('/interviewer/interviews');
                                // window.location = '/interviwer/interviews'
                            } else {
                                navigate('/admin/dashboard');
                                // window.location = '/admin/dashboard'
                            }
                            setisBtnLoading(false);
                            // setOtpInput(true);
                        } else {
                            localStorage.setItem('profilecompleted', "N");
                            localStorage.setItem('jwt', result.data.jwt);
                            Notification.openNotificationSuccess("Your profile is not completed please complete your profile!");
                            if (result.data.firstname != null) {
                                // navigate('/yourself');
                                userRoleId === '2' ? navigate("/yourself/interviewee") : navigate("/yourself/interviewer");
                            } else {
                                // navigate('/signup');
                                userRoleId === '2' ? navigate("/signup/interviewee") : navigate("/signup/interviewer");
                            }
                        }
                    } else {
                        setsubmitDataCheck(false);
                        setisBtnLoading(false);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                },
                (error) => {

                });

        }

    };

    const loginWithEmail = () => {
        setOtpForm(false);
        setMobileForm(false);
        setEmailForm(true);
    };

    const loginWithMobile = () => {
        setMobileForm(true);
        setOtpForm(false);
        setEmailForm(false);
    };

    const handleClickBack = (value) => {
        setisBtnLoadingOtp(true);
        resendOtp();
    }

    const handleClickPasswordOpen = (value) => {
        if (loginPasswordEmail == false) {
            setLoginPaaswordEmail(true);
            setLoginOtpEmail(false);
        } else {
            form.setFieldsValue({
                password: "",
            });
            setPassword("")
            setLoginOtpEmail(false);
            setLoginPaaswordEmail(false);
        }

    }

    const handleClickPasswordType = (value) => {
        setpasswordType(value)

    }

    const forgotpassword = () => {
        setforgotpasswordform(true);
        setEmailForm(false);
    };

    const handleClickBackLogin = () => {
        setforgotpasswordform(false);
        setEmailForm(true);
    };

    const handleSubmitForgotPassword = () => {
        setisBtnLoading(true);
        apiService(`login/email/forgotpassword`, 'post', { email: email, userRoleId: userRoleId }, false, '',
            result => {
                if (result.data) {
                    Notification.openNotificationSuccess(result.data.message)
                    setisBtnLoading(false);
                }
            },
            (error) => {

            });
    };

    return (
        <div className="login_page_container">
            {isLoading == false ?
                <div className="spinner">
                    <Spin size="large" />
                </div> :
                <div class='login-container'>
                    <div class='left-container'>
                        {/* {mobileForm == true ?
                            <Form name="login" form={form} onFinish={handleSubmitMobile} layout="vertical">
                                <header>
                                    <div class="header-name">
                                        <Row>
                                            <Col xs={17} sm={17} md={18} lg={18} xl={18}>
                                                <h1 className='login-heading'>Login</h1>
                                            </Col>
                                            <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                                <h4>or <Link to="/signup"><span>Sign Up</span></Link></h4>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='form-details'>
                                        <div>
                                            <Row>
                                                <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                                    <label for=''>Phone Number</label>
                                                    <Form.Item
                                                        name="mobile"
                                                        rules={[{
                                                            type: "regexp",
                                                            pattern: /^(?:\d*)$/,
                                                            message: "Value should contain just number"
                                                        },
                                                        {
                                                            min: 10,
                                                            max: 10,
                                                            message: "phone number must be 10 digits",
                                                        },
                                                        {
                                                            message: 'Please enter your phone number!',
                                                            required: true
                                                        }]}>
                                                        <input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={22} lg={22} xl={22} push={15}>
                                                    <h4 className='switch-login' onClick={loginWithEmail}>Want to use Email?</h4>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </header>
                                <footer>
                                    <div class='login-footer'>
                                        <Row >
                                            <Col xs={10} sm={10} md={12} lg={12} xl={12} push={12}>
                                                <Form.Item>
                                                    <button htmlType="submit" id='next'>LOGIN <i><ArrowRightOutlined /></i></button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </footer>
                            </Form> : null} */}
                        {otpForm == true ?
                            <Form name="login" form={form} onFinish={handleSubmitOTP} layout="vertical">
                                <header>
                                    <div class="header-name">
                                        <Row>
                                            <Col xs={17} sm={17} md={18} lg={18} xl={18}>
                                                <h1 className='login-heading'>Login</h1>
                                            </Col>
                                            {/*  {userRoleId === '2' ? <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                                <h4>or <Link to="/signup"><span>Sign Up</span></Link></h4>
                                            </Col> : <col></col>} */}
                                            <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                                <h4>or <Link to={userRoleId === '2' ? "/signup/interviewee" : "/signup/interviewer"}><span>Sign Up</span></Link></h4>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='form-details'>
                                        <div class='set'>
                                            <Row>
                                                <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                                    <label for=''>You would have received the OTP on your phone number</label>
                                                    <Form.Item
                                                        name="mobile"
                                                        rules={[{
                                                            type: "regexp",
                                                            pattern: /^(?:\d*)$/,
                                                            message: "Value should contain just number"
                                                        },
                                                        {
                                                            min: 10,
                                                            max: 10,
                                                            message: "Value should be 10 character",
                                                        },
                                                        {
                                                            message: 'Please enter your phone number!',
                                                            required: true
                                                        }]}>
                                                        <input id='mobile' placeholder="Phone Number" value={mobile} onChange={e => setMobile(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                                    {/* <label for=''>One Time Password </label> */}
                                                    <Form.Item
                                                        name="otp"
                                                        rules={[{ message: 'Please enter one time password!', required: true }]}>
                                                        <input id='otp' placeholder="Type your one time password" value={otp} onChange={e => setOtp(e.target.value)} type='number' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                    <Col xs={24} sm={24} md={22} lg={22} xl={22} push={16}>
                                                        <h4 className='switch-login' onClick={loginWithEmail}>Want to use email?</h4>
                                                    </Col>
                                                </Row> */}
                                        </div>
                                    </div>
                                </header>
                                <footer>
                                    <div class='signin-footer'>
                                        <Row >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <button id='resend' >RESEND OTP   <i><ArrowRightOutlined /></i></button>
                                            </Col>
                                            <Col xs={10} sm={10} md={12} lg={12} xl={12}>
                                                <Form.Item>
                                                    <button htmlType="submit" id='next'>LOGIN <i><ArrowRightOutlined /></i></button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </footer>
                            </Form> : null}
                        {emailForm == true ?
                            <Form name="login" form={form} onFinish={handleSubmitEmailWithOtp} layout="vertical">
                                <header>
                                    <div class="header-name">
                                        <div className='head-left'>
                                            <h1 className='login-heading'>Login</h1>
                                        </div>
                                        {/* {userRoleId === '2' ? <div className='head-right'>
                                            <h4>or <Link to="/signup"><span>Sign Up</span></Link></h4>
                                        </div> : <div></div>} */}
                                        <div className='head-right'>
                                            <h4>or <Link to={userRoleId === '2' ? "/signup/interviewee" : "/signup/interviewer"}><span>Sign Up</span></Link></h4>
                                        </div>
                                    </div>
                                    <div className='form-details login-details'>
                                        <Form.Item
                                            name="email"
                                            initialValue={emailValue}
                                            rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                            <input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />

                                        </Form.Item>
                                        {/* <div class="header-name email-form">
                                        <div className='head-left'>
                                            <h1 className='login-heading'></h1>
                                        </div>
                                        <div className='head-right'>
                                            {loginPasswordEmail == false ?
                                            <h4><span className='login-password' onClick={() => handleClickPasswordOpen(false)}>Login With Password</span></h4>
                                            :<h4><span className='login-password' onClick={() => handleClickPasswordOpen(false)}>Login With OTP</span></h4>}
                                        </div>
                                    </div> */}

                                        {(loginOtpEmail == true && loginPasswordEmail == false) ?
                                            <div>
                                                <Form.Item
                                                    name="otp"
                                                    rules={[{ message: 'Please enter the One-Time Password to login!', required: true }]}>
                                                    <input id='otp' placeholder="One-Time Password" value={otp} onChange={e => setOtp(e.target.value)} type='number' />
                                                </Form.Item>

                                            </div>
                                            : null}
                                        {loginPasswordEmail == true ?

                                            <>
                                                <Form.Item
                                                    name="password"
                                                    rules={[{ message: 'Please enter your password!', required: true }]}>
                                                    <Input.Password id='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} type={passwordType} />
                                                    {/* {passwordType == "text" ?
                                                <i class="bi bi-eye-slash"   name="password" id="password" onClick={() => handleClickPasswordType("password")}><EyeOutlined /></i>:
                                                <i class="bi bi-eye-slash"   name="password" id="password" onClick={() => handleClickPasswordType("text")}><EyeInvisibleOutlined /></i>} */}

                                                </Form.Item>
                                                {/* {passwordType == "text" ?
                                                <i class="bi bi-eye-slash" id="password" onClick={() => handleClickPasswordType("password")}><EyeOutlined /></i>:
                                                <i class="bi bi-eye-slash" id="password" onClick={() => handleClickPasswordType("text")}><EyeInvisibleOutlined /></i>} */}
                                            </>


                                            : null}

                                        {isBtnLoading == false && isBtnLoadingOtp == false ?
                                            <div class="header-name email-form">
                                                <div className='head-left'>

                                                    <h4><span className='login-password' onClick={() => forgotpassword()}>Forgot Password</span></h4>
                                                </div>
                                                <div className='head-right ps-relative'>
                                                    {loginPasswordEmail == false ?
                                                        <h4><span className='login-password' onClick={() => handleClickPasswordOpen(false)}>Login With Password</span></h4>
                                                        : <h4><span className='login-password' onClick={() => handleClickPasswordOpen(false)}>Login With OTP</span></h4>}
                                                </div>
                                            </div> : <div class="header-name email-form">
                                                <div className='head-left'>

                                                    <h4><span className='login-password'>Forgot Password</span></h4>
                                                </div>
                                                <div className='head-right ps-relative'>
                                                    {loginPasswordEmail == false ?
                                                        <h4><span className='login-password' >Login With Password</span></h4>
                                                        : <h4><span className='login-password'>Login With OTP</span></h4>}
                                                </div>
                                            </div>}
                                    </div>
                                </header>
                                <footer>
                                    {/* <div class='login-footer'>
                                        <Form.Item>
                                            {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />}  iconPosition='end'size="large">LOGIN </Button>}
                                            {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />}  iconPosition='end'loading={isBtnLoading} size="large">LOGIN </Button>}
                                        </Form.Item>
                                    </div> */}
                                    {loginOtpEmail == false ?
                                        <div class="header-name">
                                            <div className='head-left'>
                                                <h1 className='login-heading'></h1>
                                            </div>
                                            <div className='head-right'>
                                                {loginPasswordEmail == true ?
                                                    <Form.Item>
                                                        {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large">LOGIN </Button>}
                                                        {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoading} size="large">LOGIN </Button>}
                                                    </Form.Item> :
                                                    <Form.Item>
                                                        {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large">NEXT </Button>}
                                                        {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoading} size="large">NEXT </Button>}
                                                    </Form.Item>}
                                            </div>
                                        </div> :
                                        <div class="header-name">
                                            {isBtnLoading == false ?
                                                <div className='head-left'>
                                                    <h1 className='login-heading'>
                                                        <Form.Item>
                                                            {!isBtnLoadingOtp && <Button onClick={() => handleClickBack(false)} type="back" icon={<RedoOutlined />/*EDTECH ARROWRIGHT CHANGED TO REDO ARROW BY JAGA*/} size="large">RESEND OTP </Button>}
                                                            {isBtnLoadingOtp && <Button type="back" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoadingOtp} size="large">RESEND OTP </Button>}
                                                        </Form.Item>
                                                    </h1>
                                                </div> : <div className='head-left'>
                                                    <h1 className='login-heading'>
                                                        <Form.Item>
                                                            {!isBtnLoadingOtp && <Button type="back" icon={<RedoOutlined /> /*EDTECH ARROWRIGHT CHANGED TO REDO ARROW BY JAGA*/} size="large">RESEND OTP </Button>}
                                                            {isBtnLoadingOtp && <Button type="back" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoadingOtp} size="large">RESEND OTP </Button>}
                                                        </Form.Item>
                                                    </h1>
                                                </div>}
                                            <div className='head-right'>
                                                {isBtnLoadingOtp == false ?
                                                    <Form.Item>
                                                        {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large">LOGIN </Button>}
                                                        {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoading} size="large">LOGIN </Button>}
                                                    </Form.Item> : <Form.Item>
                                                        <Button type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large">LOGIN </Button>
                                                    </Form.Item>
                                                }
                                            </div>
                                        </div>
                                    }
                                </footer>
                            </Form> : null}
                        {forgotpasswordform == true ?
                            <Form name="login" form={form} onFinish={handleSubmitForgotPassword} layout="vertical">
                                <header>
                                    <div class="header-name">
                                        <div className='head-left'>
                                            <h1 className='login-heading'>Forgot Password</h1>
                                        </div>
                                        {/* {userRoleId === '2' ? <div className='head-right'>
                                            <h4>or <Link to="/signup"><span>Sign Up</span></Link></h4>
                                        </div> : <div></div>} */}
                                        <div className='head-right'>
                                            <h4>or <Link to={userRoleId === '2' ? "/signup/interviewee" : "/signup/interviewer"}><span>Sign Up</span></Link></h4>
                                        </div>
                                    </div>
                                    <div className='form-details'>
                                        <Form.Item
                                            name="email"
                                            initialValue={emailValue}
                                            rules={[{ type: "email", message: 'Please check your email address for typos and try again' }, { message: 'Please enter your email!', required: true }]}>
                                            <input placeholder="Email" type='text' value={email} onChange={e => setEmail(e.target.value)} />

                                        </Form.Item>
                                    </div>
                                </header>
                                <footer>

                                    <div class="header-name">
                                        <div className='head-left'>
                                            {
                                                isBtnLoading == false ?
                                                    <Form.Item>
                                                        <Button onClick={() => handleClickBackLogin()} type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button>
                                                    </Form.Item> : <Form.Item>
                                                        <Button type="back" icon={<ArrowLeftOutlined />} size="large">BACK </Button>
                                                    </Form.Item>}
                                        </div>
                                        <div className='head-right'>
                                            <Form.Item>
                                                {!isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' size="large">SUBMIT </Button>}
                                                {isBtnLoading && <Button htmlType="submit" type="primary" icon={<ArrowRightOutlined />} iconPosition='end' loading={isBtnLoading} size="large">SUBMIT </Button>}
                                            </Form.Item>
                                        </div>
                                    </div>


                                </footer>
                            </Form> : null}
                    </div>
                    <div className='right-container'>
                        <h1>Empower Your Interview Skills - Login to Begin Your Journey</h1>
                        <img src={loginImg} alt={loginImg} />
                    </div>
                </div>
            }
        </div>
    );
};

export default Login;
