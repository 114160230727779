// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_menu {
  padding-top: 10px;
}
.custom_menu span.material-icons {
  font-size: 18px;
}
.custom_menu footer {
  margin-top: 315px;
}
.custom_menu footer.interviewer-footer {
  margin-top: 315px !important;
}
.custom_menu footer.admin-footer {
  margin-top: 265px !important;
}
.custom_menu h3 {
  color: #fff;
  font-size: 14px;
}
.custom_menu .ant-menu-item {
  min-height: 20px;
  display: flex;
  align-items: center;
  width: 95% !important;
}
.custom_menu span.material-icons {
  padding-right: 10px;
}
.custom_menu .user-details {
  margin-top: 15px;
  margin-left: 10px;
  color: white;
}
.custom_menu header {
  border-top: 1px dashed;
}
.custom_menu .avatar {
  margin-left: 20px;
  margin-top: 10px;
}
.custom_menu .bottom-footer {
  margin: 20px 20px 25px 30px;
  background-color: white;
}
.custom_menu .bottom-footer h5 {
  color: #a6adb4;
}

.dashboad-header h2 {
  color: #fff;
  margin: 25px 0 0 43px;
}`, "",{"version":3,"sources":["webpack://./src/pages/main-layout/main-layout.scss"],"names":[],"mappings":"AAAA;EAiBC,iBAAA;AAfD;AADC;EACC,eAAA;AAGF;AADC;EACC,iBAAA;AAGF;AADC;EACC,4BAAA;AAGF;AADC;EACC,4BAAA;AAGF;AADC;EACC,WAAA;EACA,eAAA;AAGF;AAAC;EACC,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AAEF;AAAC;EACC,mBAAA;AAEF;AAAC;EACC,gBAAA;EACA,iBAAA;EACA,YAAA;AAEF;AACC;EACC,sBAAA;AACF;AACC;EACC,iBAAA;EACA,gBAAA;AACF;AACC;EACC,2BAAA;EACA,uBAAA;AACF;AAAE;EACC,cAAA;AAEH;;AAGC;EACC,WAAA;EACA,qBAAA;AAAF","sourcesContent":[".custom_menu {\n\tspan.material-icons {\n\t\tfont-size: 18px;\n\t}\n\tfooter {\n\t\tmargin-top: 315px;\n\t}\n\tfooter.interviewer-footer {\n\t\tmargin-top: 315px !important;\n\t}\n\tfooter.admin-footer {\n\t\tmargin-top: 265px !important;\n\t}\n\th3{\n\t\tcolor: #fff;\n\t\tfont-size: 14px;\n\t}\t\n\tpadding-top: 10px;\n\t.ant-menu-item {\n\t\tmin-height: 20px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\twidth: 95% !important;\n\t}\n\tspan.material-icons {\n\t\tpadding-right: 10px;\n\t}\n\t.user-details{\n\t\tmargin-top: 15px;\n\t\tmargin-left: 10px;\n\t\tcolor: #ffff;\n\t\n\t}\n\theader {\n\t\tborder-top: 1px dashed;\n\t}\n\t.avatar {\n\t\tmargin-left: 20px;\n\t\tmargin-top: 10px;\n\t}\n\t.bottom-footer {\n\t\tmargin: 20px 20px 25px 30px;\n\t\tbackground-color: #ffff;\n\t\th5 {\n\t\t\tcolor: #a6adb4;\n\t\t}\n\t}\n}\n.dashboad-header{\n\th2{\n\t\tcolor:#fff;\n\t\tmargin: 25px 0 0 43px ;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
